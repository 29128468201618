



































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/CampaignRamadhanController'
import Modal from '../components/Modal/index.vue'
import Header from '../components/Header/index.vue'
import Banner from '@/app/ui/assets/CampaignRamadhan/banner.vue'
import DonationBox from '@/app/ui/assets/CampaignRamadhan/donation_box.vue'
import Toys from '@/app/ui/assets/CampaignRamadhan/toys.vue'
import Book from '@/app/ui/assets/CampaignRamadhan/book.vue'
import Clothes from '@/app/ui/assets/CampaignRamadhan/clothes.vue'
import Rice from '@/app/ui/assets/CampaignRamadhan/rice.vue'
import ArrowDown from '@/app/ui/assets/CampaignRamadhan/arrow_down_icon.vue'
import ArrowUp from '@/app/ui/assets/CampaignRamadhan/arrow_up_icon.vue'
import HowToDonate1 from '@/app/ui/assets/CampaignRamadhan/how_to_donate_1.vue'
import HowToDonate2 from '@/app/ui/assets/CampaignRamadhan/how_to_donate_2.vue'
import HowToDonate3 from '@/app/ui/assets/CampaignRamadhan/how_to_donate_3.vue'
import HowToDonate4 from '@/app/ui/assets/CampaignRamadhan/how_to_donate_4.vue'
import DropdwonDownBackground from '@/app/ui/assets/CampaignRamadhan/background_dropdown.vue'
import ValidateTimeIcon from '@/app/ui/assets/CampaignRamadhan/validate_time_icon.vue'
import ValidateMaxDonateIcon from '@/app/ui/assets/CampaignRamadhan/validate_max_donate_icon.vue'
import ACTLogo from '@/app/ui/assets/CampaignRamadhan/ACT_logo.vue'

@Component({
  components: {
    Header,
    Modal,
    Banner,
    DonationBox,
    Toys,
    Book,
    Clothes,
    Rice,
    ArrowDown,
    ArrowUp,
    HowToDonate1,
    HowToDonate2,
    HowToDonate3,
    HowToDonate4,
    DropdwonDownBackground,
    ValidateTimeIcon,
    ValidateMaxDonateIcon,
    ACTLogo,
  },
})
export default class LandingPage extends Vue {
  controller = controller
  isOpenDropDownWays = false
  isOpenDropDownTerms = false
  isOpenModal = false
  modalType = ''
  parameters = {
    username: '',
    phonenumber: '',
  }

  created() {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        username: queries.username || '',
        phonenumber: queries.phone_number || '',
      }
    }
  }

  private handleDropDownWays() {
    this.isOpenDropDownWays = !this.isOpenDropDownWays
  }

  private handleDropDownTerms() {
    this.isOpenDropDownTerms = !this.isOpenDropDownTerms
  }

  private handleBeganDonate() {
    const { getValidationStatus, resetValidateData } = controller
    resetValidateData()
    getValidationStatus(this.parameters.phonenumber)
  }

  private handleCloseModal() {
    this.isOpenModal = false
  }

  @Watch('controller.validateData.isMaxDonate')
  onIsIsMaxDonateChanged(status: boolean) {
    if (status) {
      this.modalType = 'validateMaxDonate'
      this.isOpenModal = true
    }
  }

  @Watch('controller.validateData.isInvalidTime')
  onIsInvalidTimeChanged(status: boolean) {
    if (status) {
      this.modalType = 'validateTime'
      this.isOpenModal = true
    }
  }

  @Watch('controller.validateData.isValidated')
  onIsValidateChanged(status: boolean) {
    if (status) {
      this.modalType = ''
      this.$router.push({
        name: 'CampaignRamadhanFormPage',
        params: {
          username: this.parameters.username,
          phonenumber: this.parameters.phonenumber,
        },
      })
    }
  }
}
